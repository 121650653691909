export const appHead = {"meta":[{"charset":"utf-8"},{"name":"author","content":"NAYOO CORPORATION CO., LTD., Thailand"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=5"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"shortcut icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"144x144","type":"image/x-icon","href":"/apple-touch-icon-144x144.png"},{"rel":"apple-touch-icon","sizes":"180x180","type":"image/x-icon","href":"/apple-touch-icon.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"preconnect","href":"https://baanprompt-api.staging.nayoo.co","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://api.staging.nayoo.co","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://d35q2c276lvyi.cloudfront.net","crossorigin":"anonymous"},{"rel":"preconnect","href":"https://baanprompt-nayoo.sentry.io","crossorigin":"anonymous"},{"rel":"dns-prefetch","href":"https://baanprompt-api.staging.nayoo.co"},{"rel":"dns-prefetch","href":"https://api.staging.nayoo.co"},{"rel":"dns-prefetch","href":"https://fonts.gstatic.com"},{"rel":"dns-prefetch","href":"https://d35q2c276lvyi.cloudfront.net"},{"rel":"dns-prefetch","href":"https://baanprompt-nayoo.sentry.io"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"bodyAttrs":{"class":"demo"},"charset":"utf-8","titleTemplate":"%s | บ้านพร้อม"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'